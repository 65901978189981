<template>
    <div class="multiple-choice">
        <div class="item" v-for="(d, i) in list" :key="i">
            <input v-model="list[i]" placeholder="code language" @keyup="valueChanged">
            <div class="trash" @click="del(i)">delete</div>
        </div>
        <div class="add" @click="add">Add language codes</div>
    </div>
</template>

<script>
export default {
    name: 'ArrayMultiple',
    props: ['value'],
    data() {
        return {
            list: JSON.parse(JSON.stringify(this.value)) || []
        }
    },
    methods: {
        add() {
            this.list.push('');
            this.valueChanged()
        },
        del(i) {
            this.list.splice(i, 1)
            this.valueChanged()
        },
        valueChanged() {
            this.$emit('input', this.list.filter(function (el) {
                return el != false;
            }))
        }
    }
}
</script>

<style lang="scss">
.multiple-choice {
    margin-left: 10px;
    .add {
        width: 200px;
        background: #4BB543;
        border-radius: 6px;
        color: #fff;
        text-align: center;
        padding: 10px 0;
        cursor: pointer;
        margin: 10px 0;

    }
    .item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .trash {
        margin-left: 10px;
        font-size: 15px;
        cursor: pointer;
    }
    input {
        width: 100px;
        height: 35px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        border: 1px solid #dfdfdf;
    }
}
</style>