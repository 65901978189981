export const REPEATS = {
    default: {
        title: 'Не задано'
    },
    everyDay: {
        title: 'Ежедневно'
    },
    single: {
        title: 'Однократно'
    },
    everyWeekDays: {
        title: 'В будние дни'
    },
    everyMonth: {
        title: 'Ежемесячно ({d} числа)'
    },
    everyMonthDay: {
        title: 'Ежемесячно ({w})'
    },
    dayOfWeek: {
        title: 'Дни недели'
    }
}

export const isSameDay = (d1, d2) => {
    return (
        d1.getFullYear() === d2.getFullYear() &&
        d1.getDate() === d2.getDate() &&
        d1.getMonth() === d2.getMonth()
    )
}

export const monthMapper = (month) => {
    switch (month) {
        case 1:
            return 'J'
        case 2:
            return 'F'
        case 3:
            return 'M'
        case 4:
            return 'A'
        case 5:
            return 'May'
        case 6:
            return 'Ju'
        case 7:
            return 'Jul'
        case 8:
            return 'Aug'
        case 9:
            return 'Se'
        case 10:
            return 'O'
        case 11:
            return 'N'
        case 12:
            return 'D'
    }
}