<template>

    <div class="input-number">
        <md-button @click="add(-1)" class="md-elevation-1 md-fab md-mini md-accent"><md-icon>remove</md-icon></md-button>
        <div class="value">{{placeholder ? (placeholder + ": ") : ""}}<i>{{value}}</i> {{unit ? unit : ""}}</div>
        <md-button @click="add(1)" class="md-elevation-1 md-fab md-mini md-primary"><md-icon>add</md-icon></md-button>
    </div>

</template>


<script>
    export default {
        components: {},
        props: ["value", "placeholder", "unit", "min", "max"],
        data: () => ({
            lastClickTime: 0,
            fastClicks: 0
        }),
        methods: {
            add(step) {

                // Get current time
                const curTime = (new Date).getTime();

                // Fast click
                if(curTime - this.lastClickTime < 200) {
                    if(this.fastClicks++ > 30) this.fastClicks = 30;
                } else
                    this.fastClicks = 0;

                console.log(this.fastClicks, curTime - this.lastClickTime);

                // Store last click time
                this.lastClickTime = curTime;

                // Calc new value
                let nv = this.value + step * (1 + this.fastClicks);

                if(nv < this.min) nv = this.min;
                if(nv > this.max) nv = this.max;

                // Send update
                this.$emit("input", nv)
            }
        }
    }
</script>

<style lang="scss">

    @import "src/sass/variables";

    .input-number {
        display: flex;
        border-bottom: 1px solid #ddd;
        .value {
            flex-grow: 10;
            vertical-align: center;
            line-height: 40px;
            text-align: center;
            //font-weight: bold;

            i {
                color: $orange;
                font-weight: bold;
                font-style: normal;
            }
        }

        button {
            width:25px !important;
            height:25px !important;
        }

    }
</style>
