<template>
    <div class="base-icons">
        <div class="list">
            <div
                v-for="(item, k) in items"
                :key="k"
                class="icon-wrapper"
                :class="{ 'active': value === item }"
            >
                <img
                    @click="select(item)"
                    :src="$baseIconUrl(item)"
                    :key="k"
                    alt="Icon"
                >

                <div
                    v-if="value === item"
                    class="done-icon"
                >
                    <md-icon>done</md-icon>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        props: ["items", "value"],

        // Get data
        data: () => ({
            selectedIcon: 1,
            file: false,
        }),

        methods: {

            /**
             * Select avatar
             * @param k
             */
            select(k) {
                // Select from the list
                this.selectedIcon = k;

                // Emit change
                this.$emit('input', k);
            },
        }
    }
</script>

<style lang="scss" scoped>

    @import "/src/sass/variables";

    .icon-wrapper {
        position: relative;
        margin-left: 10px;
        height: 48px;
        width: 48px;
        min-height: 48px;
        min-width: 48px;

        &:first-child {
            margin-left: 0;
        }

        img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
        }

        .done-icon {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            border: 2px solid white;
            position: absolute;
            bottom: -6px;
            right: -6px;
            background-color: $orange;
            text-align: center;
            line-height: 16px;

            .md-icon {
                color: white;
                width: 18px;
                height: 18px;
                min-height: 16px;
                min-width: 16px;
                font-size: 16px !important;
            }
        }
    }

    .icon-border.active {
        border-color: $orange;
    }

    .base-icons {
        width: 100%;
        margin-bottom: 15px;

        .list {
            width: 100%;
            display: flex;
            flex-direction: row;
            overflow-x: scroll;
            padding: 30px 0 10px 0;
        }
    }
</style>
