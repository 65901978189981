<template>
    <div class="calendar-wrap">
        <v-date-picker ref="calendar" :select-attribute="attr" :attributes="attributes" v-model="range" color="orange" v-if="!showWrap" :min-date="new Date()" />

        <div class="bottom-items" v-if="!showWrap">
            <div class="item" :class="{'with-cross': settings.repeat && settings.repeat !== 'default'}">
                <div class="content" @click="setWrap('repeat')">
                    <p>
                        <md-icon>repeat</md-icon>
                        {{$t('ttlMakeRepeat')}}
                    </p>
                    <p class="value">{{getText().replace('{d}', d).replace('{w}', w >= 0 ? $t('ttlDatePickerShorterDay' + days[w]) : w)}}</p>
                </div>
                <div class="cross" v-if="settings.repeat && settings.repeat !== 'default'" @click="clearField('repeat')">
                    <md-icon>
                        close
                    </md-icon>
                </div>
            </div>
            <div class="item" :class="{disabled: !settings.repeat || settings.repeat === 'default', 'with-cross': settings.timeToDo}">
                <div class="content" @click="setWrap('time')">
                    <p>
                        <md-icon>query_builder</md-icon>
                        {{$t('ttlMakeFor')}}
                    </p>
                    <p class="value">{{settings.timeToDo || $t('ttlEmptyField')}}</p>
                </div>
                <div class="cross" v-if="settings.timeToDo" @click="clearField('timeToDo')">
                    <md-icon>
                        close
                    </md-icon>
                </div>
            </div>
        </div>
        <div class="repeats" v-if="showWrap === 'repeat'">
            <h2>{{ $t('ttlRepeat') }}</h2>
            <div class="repeat" v-for="(r, key, i) in repeats" :key="i" @click="setRepeat(key)" :class="{active: settings.repeat === key}">{{$t('ttlRepeat-' + key).replace('{d}', d).replace('{w}', w >= 0 ? $t('ttlDatePickerShorterDay' + days[w]) : w)}}</div>
            <div class="days" v-if="settings.repeat === 'dayOfWeek'">
                <div class="day" v-for="(d, i) in days" :key="i" @click="pickDay(i)" :class="{active: pickedDays.includes(i)}">{{$t('ttlDatePickerShorterDay' + d)}}</div>
            </div>
            <span class="error" v-if="errorRepeat">{{ errorRepeat }}</span>
            <div class="button">
                <md-button @click="hideRepeat">OK</md-button>
            </div>
        </div>
        <div class="time-picker" v-show="showWrap === 'time'">
            <vue-clock-picker ref="clock" :active-color="'#F66343'" done-text="Ok" :cancel-text="$t('ttlDatePickerCancel')" v-model="settings.timeToDo" @cancel="setTime" @close="setTime"/>
        </div>

        <div class="bottom-buttons" v-if="!showWrap">
            <md-button @click="cancel">{{ $t('ttlDatePickerCancel') }}</md-button>
            <md-button @click="emitVals">OK</md-button>
        </div>
    </div>
</template>

<script>
import {REPEATS} from '@/utils/const';
import VueClockPicker from '@pencilpix/vue2-clock-picker';
import '@pencilpix/vue2-clock-picker/dist/vue2-clock-picker.min.css';

export default {
    name: 'CalendarPopUp',
    props: ['values'],
    components: {VueClockPicker},
    data() {
        return {
            showWrap: false,
            repeats: REPEATS,
            settings: {
                repeat: null,
                timeToDo: null,
            },
            attributes: [
                {
                    highlight: true,
                }
            ],
            range: null,
            attr: {
                highlight: {
                    base: {
                        style: {
                            backgroundColor: 'rgba(246,99,67,0.44)', // blue
                        },
                        contentStyle: {
                            color: '#ffffff'
                        }
                    },
                },
            },
            days: ['M', 'T', 'W', 'Thu', 'F', 'Sat', 'S'],
            pickedDays: [],
            d: new Date().getDate(),
            w: new Date().getDay() - 1 === -1 ? 6 : new Date().getDay() - 1,
            updateRange: 0,
            errorRepeat: ''
        }
    },
    watch: {
        range: function (val) {
            if (val) {
                this.d = val.getDate()
                this.w = val.getDay() - 1
                if (this.w == -1) {
                    this.w = 6
                }
                if (this.updateRange > 1) {
                    this.attributes[0].dates = val
                }
            } else {
                if (this.settings.repeat !== 'dayOfWeek') {
                    if (this.updateRange > 1) {
                        this.attributes[0].dates = new Date()
                    }
                    this.settings.repeat = 'default'
                }
            }
            this.updateRange += 1

        }
    },
    created() {

    },
    mounted() {
        if (this.values && !Array.isArray(this.values)) {
            let o = JSON.parse(JSON.stringify(this.values))
            this.settings.repeat = o.repeat
            this.settings.timeToDo = o.time
            this.pickedDays = o.days
            this.range = new Date(o.day)
            this.markCalendar()

        } else {
            this.range = new Date()
            this.attributes[0].dates = new Date()
            this.updateRange = 2
        }
    },
    methods: {
        clearField(type) {
            this.settings[type] = null
            if (type === 'repeat') {
                this.settings.timeToDo = null
                this.markCalendar()
            }
        },
        hideRepeat() {
            if (this.settings.repeat === 'dayOfWeek' && !this.pickedDays.length) {
                this.errorRepeat = this.$t('ttlDayOfWeeksEmpty')
                return false
            }
            this.showWrap = false
            this.markCalendar()
            if (this.settings.repeat === 'default') {
                this.settings.timeToDo = null
            }
        },
        cancel() {
            this.$emit('update', null)
        },
        setWrap(item) {
            if (item === 'time' && (!this.settings.repeat || this.settings.repeat === 'default')) {
                return false;
            }
            this.showWrap = item
            if (item === 'time') {
                this.$nextTick(() => {
                    this.$refs.clock.open()
                })
            }
        },
        setRepeat(key) {
            this.settings.repeat = key
        },
        markCalendar() {
            let date = new Date()
            switch (this.settings.repeat) {
                case 'everyWeekDays':
                    this.attributes[0].dates = {
                        start: new Date(date.getFullYear(), 0, 1),  // Jan 1st, 2018
                        end: new Date(date.getFullYear() + 1, 0, 1),    // Jan 1st, 2019
                        weekdays: [2, 3, 4, 5, 6]              // ...on Sundays and Saturdays
                    }
                    break;
                case 'everyDay':
                    this.attributes[0].dates = {
                        start: new Date(date.getFullYear(), 0, 1),  // Jan 1st, 2018
                        end: new Date(date.getFullYear() + 1, 0, 1),    // Jan 1st, 2019
                        weekdays: [1, 2, 3, 4, 5, 6, 7]
                    }
                    break;
                case 'single':
                    this.attributes[0].dates = this.range ?? new Date()
                    break;
                case 'everyMonth':
                    var dates = {
                        start: date,
                        monthlyInterval: 1,
                        on: [                               // ...on...
                            { days: date.getDate() }                      // ...or the 15th of the month
                        ]
                    }
                    if (this.range) {
                        dates.start = this.range
                        dates.on[0].days = this.range.getDate()
                    }
                    this.attributes[0].dates = dates
                    break;
                case 'everyMonthDay':
                    var dts = {
                        start: new Date(date.getFullYear(), 0, 1),  // Jan 1st, 2018
                        end: new Date(date.getFullYear() + 1, 0, 1),    // Jan 1st, 2019
                        weekdays: [date.getDay() + 1]
                    }
                    if (this.range) {
                        dts.weekdays[0] = this.range.getDay() + 1
                    }
                    this.attributes[0].dates = dts
                    break;
                case 'dayOfWeek':
                    var pDays = JSON.parse(JSON.stringify(this.pickedDays))
                    pDays = pDays.map(day => {
                        if (day + 2 <= 7) {
                            return day + 2
                        }
                        return 1
                    })

                    this.attributes[0].dates = {
                        start: new Date(date.getFullYear(), 0, 1),  // Jan 1st, 2018
                        end: new Date(date.getFullYear() + 1, 0, 1),    // Jan 1st, 2019
                        weekdays: pDays
                    }
                    setTimeout(() => {
                        this.range = null
                    })
                    break;
                default:
                    this.range = null
                    this.attributes[0].dates = date
                    break;
            }

        },
        pickDay(i) {
            if (this.pickedDays.includes(i)) {
                this.pickedDays.splice(this.pickedDays.indexOf(i), 1)
                return;
            }
            this.pickedDays.push(i)
            if (this.pickedDays.length) {
                this.errorRepeat = '';
            }
        },
        setTime() {
            this.showWrap = false
        },
        getText() {
            if (!this.settings.repeat || this.settings.repeat === 'default') return this.$t('ttlEmptyField')
            let pDays = JSON.parse(JSON.stringify(this.pickedDays))

            switch (this.settings.repeat) {
                case 'dayOfWeek':
                    return pDays.sort().map(day => this.daysHelper(day)).join()
                default:
                    return this.$t('ttlRepeat-' + this.settings.repeat)
            }
        },
        daysHelper(day) {
            return this.$t('ttlDatePickerShorterDay' + this.days[day])
        },
        emitVals() {
            if (this.settings.repeat === 'default') {
                this.$emit('update', null)
                return true;
            }
            let o = {}
            o.repeat = this.settings.repeat
            o.time = this.settings.timeToDo
            o.days = this.pickedDays
            if (this.range) {
                o.day = this.range
            } else {
                o.day = new Date()
            }
            this.$emit('update', o)
        }
    }
}
</script>

<style lang="scss">
    .calendar-wrap {
        .bottom-buttons {
            width: 50%;
            float: right;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .md-button {
                width: 50%;
                min-width: auto;
                .md-button-content {
                    color: #f66343;
                }
            }
        }
        .bottom-items {
            .item {
                width: 100%;
                margin-bottom: 15px;
                .content {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                &.with-cross {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .content {
                        width: calc(100% - 20px);
                        p {
                            color: #22262d;
                        }
                        .value {
                            margin-right: 0px;
                            color: #32986f;
                        }
                    }
                    .cross {
                        width: 20px;
                        height: 20px;
                        margin-left: 15px;
                        text-align: center;
                       .md-icon {
                           width: 16px;
                           height: 16px;
                           min-width: 16px;
                           max-width: 16px;
                           font-size: 16px!important;
                       }
                    }
                }
                &.disabled {
                    opacity: 0.5;
                }
                p {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 19px;
                    color: #8E8E8E;
                    margin: 0;
                    .md-icon {
                        width: 20px;
                        height: 20px;
                        min-width: 20px;
                        margin-right: 10px;
                        &.md-icon-font {
                            color: #32986f;
                        }
                    }
                }
                .value {
                    margin-right: 20px;
                }
            }
        }
        .vc-container {
            --orange-600: #F66343;
            --orange-200: rgba(246,99,67,0.44);
            width: 100%;
            border: none;

            .vc-header {
                margin-bottom: 20px;
                .vc-title {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: #8E8E8E;
                }
            }
            .vc-arrows-container {
                padding: 4px 10px;
            }
        }

        .time-picker {
            min-height: 440px;
            .clock-picker__dialog {
                &-body {
                    -webkit-border-radius: 0;
                    -moz-border-radius: 0;
                    border-radius: 0;
                    margin: -230px -158px;
                }
                &-header {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 115px;
                    background: #F66343;
                    padding: 0;
                    span, b {
                        font-weight: 400;
                        font-size: 56px;
                        line-height: 76px;
                        text-align: center;
                    }
                }
                &-content {
                    margin-top: 12px;
                    margin-bottom: 12px;
                }

                &-actions {
                    width: 50%;
                    float: right;
                    padding: 0;
                    height: 40px;
                }
            }
            .clock-picker__canvas {
                text-align: center;
            }
        }

        .repeats {
            h2 {
                text-align: center;
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                text-transform: uppercase;
                color: #22262D
            }
            .error {
                font-size: 12px;
                color: #E43700;
                margin: 7px 0 0 0;
                display: inline-block;
            }
            .repeat {
                margin-bottom: 14px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.0015em;
                color: #8E8E8E;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                position: relative;

                &:before {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    content: "";
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    border-radius: 50%;
                    margin-right: 14px;
                    border: 1px solid #C4C4C4;
                }

                &.active {
                    &:before {
                        border-color: #32986F;
                    }

                    &:after {
                        content: '';
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        top: 6px;
                        left: 4px;
                        position: absolute;
                        background: #32986F;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        border-radius: 50%;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .days {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row;
                //padding: 0 15px;

                .day {
                    width: 36px;
                    height: 36px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    border-radius: 50%;
                    border: 1px solid #D4CFCE;
                    color: #22262D;
                    &.active {
                        color: #fff;
                        background: #F66343;
                        border: 2px solid #E43700;
                    }
                }
            }

            .button {
                width: 100%;
                .md-button {
                    width: 100%;
                    background: #F66343;
                    border-radius: 5px;
                    margin: 12px auto 12px auto;

                    .md-button-content {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 19px;
                        color: #fff;
                    }
                }
            }
        }
    }
</style>
