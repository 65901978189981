<template>
    <div class="radio-buttons">
        <md-radio v-for="(item, i) in arrValues" v-model="checked" :value="item.val" :key="i" @change="valueChanged">{{item.title}}</md-radio>
    </div>
</template>

<script>
    export default {
        name: "Radio",
        props: ['value', 'items'],
        data() {
            return {
                arrValues: this.items ?? [],
                checked: this.value ?? null
            }
        },
        methods: {
            valueChanged() {
                this.$emit('input', this.checked)
            }
        }
    }
</script>

<style lang="scss">
    .radio-buttons {
        margin-top: 20px;
        .md-radio {
            .md-radio-label {
                height: auto;
                line-height: initial;
                top: 5px;
            }
        }
    }
</style>
