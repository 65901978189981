<template>

    <div>

        <form novalidate @submit.prevent="validateForm" class="ap-form">

            <template v-for="(field, alias) in fields">

                <div :key="alias" :class="'control-' + field.type" v-if="!field.show || (field.show(formValues))">
                    <md-field :class="getValidationClass(alias)">

                        <label v-if="field.type!=='date'" :for="alias" class="cntr-label">{{field.title}}</label>

                        <md-autocomplete v-if="field.type==='autocomplete'" :name="alias" :id="alias"
                                         v-model="formValues[alias]" :md-options="optionsSource(field)"/>

                        <md-input
                            v-if="field.type==='string'"
                            :name="alias"
                            :id="alias"
                            v-model="formValues[alias]"
                            :disabled="sending"
                            :placeholder="field.placeholder || ''"
                            :pattern="field.pattern || ''"
                            :type="field.fieldType ? field.fieldType : 'text'"
                        />

                        <md-input v-if="field.type==='password'" type="password" :name="alias" :id="alias"
                                  v-model="formValues[alias]" :disabled="sending"/>

                        <md-textarea v-if="field.type==='textarea'" :name="alias" :id="alias"
                                     v-model="formValues[alias]" :disabled="sending"/>

                        <md-switch v-if="field.type==='switch'" v-model="formValues[alias]" value="1">
                            {{field.title}}
                        </md-switch>

                        <md-switch v-if="field.type==='switch-bool'" v-model="formValues[alias]" :value="true">
                            {{field.title}}
                        </md-switch>

                        <upload-photos-field
                            v-if="field.type === 'uploadPhoto'"
                            :field="field"
                            v-model="formValues[alias]"
                        />

                        <base-icon-selector
                            v-if="field.type === 'base_icon'"
                            v-model="formValues[alias]"
                            :items="field.icons"
                        />

                        <md-datepicker :md-model-type="Number" v-if="field.type==='date'" :name="alias" :id="alias"
                                       v-model="formValues[alias]" :disabled="sending">
                            <label>{{field.title}}</label>
                        </md-datepicker>

                        <md-select v-if="field.type==='select'" v-model="formValues[alias]" :name="alias" :id="alias"
                                   :multiple="field.multiple" :md-class="alias">
                            <template v-for="(val, id) in optionsSource(field)">
                                <md-option :key="id" :value="id">{{val}}</md-option>
                            </template>
                        </md-select>

                        <template v-if="field.type==='text'">
                            <div v-html="fieldText(field)"></div>
                        </template>

                        <template v-if="field.type==='range'">

                            <range-slider
                                    :disabled="field.disabled"
                                    class="slider"
                                    :min="field.min"
                                    :max="field.max"
                                    :step="field.step"
                                    v-model="formValues[alias]">
                            </range-slider>

                            <span class="value">{{formValues[alias]}}</span>

                        </template>

                        <template v-if="field.type === 'numeric'">
                            <field-input-numeric
                                :unit="field.unit"
                                :min="field.min"
                                :max="field.max"
                                :placeholder="field.placeholder"
                                v-model="formValues[alias]"
                            />
                        </template>

                        <template v-if="field.type === 'checkbox'">
                            <checkbox
                                    :items="field.options"
                                    v-model="formValues[alias]"
                            ></checkbox>
                        </template>

                        <template v-if="field.type === 'schedule'">
                            <field-schedule v-model="formValues[alias]" />
                        </template>

                        <template v-if="field.type === 'array_multiple'">
                            <array-multiple v-model="formValues[alias]" />
                        </template>

                        <template v-if="field.type === 'radio'">
                            <radio
                                    :items="field.options"
                                    v-model="formValues[alias]"
                            ></radio>
                        </template>

                        <span class="md-error" v-if="$v.form[alias] && !$v.form[alias].required">The {{field.title}} is required</span>

                    </md-field>
                </div>

            </template>

            <md-progress-bar md-mode="indeterminate" v-if="sending"/>

            <div class="buttons">
                <template v-for="(but, alias) in buttons">
                    <span :key="alias">
                        <template v-if="alias=='close'">
                            <md-button type="button" class="md-transparent bt-close" @click="$emit('close')">{{$t('btClose')}}</md-button>
                        </template>
                        <template v-else>
                         <md-button :type="but.type" :class="'md-primary ' + but.class + ' bt-' + alias"
                                   @click="buttonClick(but, alias)" :disabled="sending">{{but.title}}</md-button>
                        </template>
                    </span>
                </template>
            </div>
        </form>
    </div>

</template>

<script>
    import Vue from 'vue'
    import {validationMixin} from 'vuelidate'
    import {
        required,
    } from 'vuelidate/lib/validators'

    import RangeSlider from 'vue-range-slider'
    import 'vue-range-slider/dist/vue-range-slider.css'
    import UploadPhotosField from "@/components/UploadPhotosField";
    import FieldInputNumeric from "./FieldInputNumeric";
    import BaseIconSelector from "./form/BaseIconSelector";
    import Checkbox from "./form/Checkbox";
    import Radio from "./form/Radio";
    import ArrayMultiple from '@/components/form/ArrayMultiple';
    import FieldSchedule from '@/components/FieldSchedule';

    // Custom validator
    const requiredMultiple = (value) => {
        return value ? Object.values(value).length > 0 : 0;
    }

    export default {
        props: ["fields", "values", "buttons"],
        components: {ArrayMultiple, Checkbox, UploadPhotosField, RangeSlider, FieldInputNumeric, BaseIconSelector, Radio, FieldSchedule},
        name: 'FormValidation',
        mixins: [validationMixin],
        data() {
            return {
                formSaved: false,
                formValues: {},
                sending: false,
                lastUser: null
            }
        },

        // Get validation rules
        validations() {
            return {
                form: this.rules
            }
        },

        // Component mounted
        created() {
            // Set original values to the form
            for (let val in this.values) {
                // Skip non property
                // eslint-disable-next-line no-prototype-builtins
                if (!this.values.hasOwnProperty(val)) continue;

                // Get value
                let vValue = this.values[val];

                // Store value into the form
                Vue.set(this.formValues, val, vValue);
            }

            // Fill form vals
            for (let field in this.fields) {
                // Skip property
                // eslint-disable-next-line no-prototype-builtins
                if (!this.fields.hasOwnProperty(field)) continue;

                // Init form fields
                if (this.values && this.values[field] !== undefined) {
                    // Set value
                    Vue.set(this.formValues, field, this.values[field]);
                }
            }
        },

        // Computed vars
        computed:
            {
                /**
                 * Validations list
                 * @returns {{}}
                 */
                rules() {

                    // Field
                    let valids = {};

                    // Create validation rules
                    for (let field in this.fields) {

                        // Skip not own props
                        // eslint-disable-next-line no-prototype-builtins
                        if (!this.fields.hasOwnProperty(field)) continue;

                        // Skip not shown items
                        if (this.fields[field].show && !this.fields[field].show(this.formValues)) continue;

                        // Get field
                        let vals = this.fields[field].validation;

                        // Process validation
                        if (vals) for (let rule of vals) {

                            // Validation function
                            let vf = false;

                            // Get validatoon function
                            switch (rule) {
                                case "required":
                                    vf = required;
                                    break;

                                case "requiredMultiple":
                                    vf = requiredMultiple;
                                    break;
                            }

                            // Set validation function
                            if (vf) {
                                // Init object
                                if (!valids[field]) valids[field] = {};

                                // Set value
                                valids[field][rule] = vf;
                            }
                        }
                    }

                    // Return list
                    return valids;
                },


                /**
                 * Simulate form for validation
                 */

                form() {
                    return this.formValues;
                }
            },

        // Methods list
        methods: {

            /**
             * Options source
             */
            optionsSource(field) {

                // Check for field config
                if (!field.options) return [];

                // Check for function
                if (typeof field.options === "function") return field.options(this.formValues);

                // Return static options
                return field.options;
            },

            /**
             * Options source
             */
            fieldText(field) {

                // Check for field config
                if (!field.text) return "";

                // Check for function
                if (typeof field.text === "function") return field.text(this.formValues);

                // Return static options
                return field.text;
            },

            /**
             * Validation classes
             */
            getValidationClass(fieldName) {
                const field = this.$v.form[fieldName]

                // Check for result
                if (field) {
                    return {
                        'md-invalid': field.$invalid && field.$dirty
                    }
                }
            },

            /**
             * Save form
             */
            saveForm() {

                // Sending process
                this.sending = true
                this.formSaved = true
                this.sending = false

                // Emit changes
                this.$emit('change', this.formValues);
            },

            /**
             * Button click
             */

            buttonClick(but, alias) {
                // Store submit action
                Vue.set(this.formValues, "action", alias);

                // Emit event
                if (but.emit) {
                    // Emit action
                    this.$emit(but.emit);
                }
            },

            /**
             * Validate form
             */
            validateForm() {

                // Validate form
                this.$v.$touch()

                // Check for validation result and submit form
                if (!this.$v.$invalid) {
                    this.saveForm()
                }
            }
        }
    }
</script>

<style lang="scss">
    @import './src/sass/ap-form.scss';
</style>

<style scoped>
    .ap-form-icon-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .ap-form-icon-wrapper img {
        max-width: 200px;
    }
</style>
