<template>
    <div class="wrap">
        <div class="picker-time-field" @click="openPopUp">
            <md-icon>calendar_month</md-icon>
            <span>
                {{ getText().replace('{d}', d).replace('{w}', w >= 0 ? $t('ttlDatePickerShorterDay' + days[w]) : w) }}
            </span>
        </div>
        <md-dialog
            v-if="showPopup"
            :md-close-on-esc="true"
            :md-click-outside-to-close="false"
            :md-active.sync="showPopup"
            :md-fullscreen="false"
            :md-backdrop="true"
            class="popup-dialog"
            @md-clicked-outside="showPopup = false">
            <calendar-pop-up @update="setValues" :values="value" />
        </md-dialog>
    </div>
</template>

<script>
import CalendarPopUp from '@/components/CalendarPopUp';
export default {
    name: 'FieldSchedule',
    props: ['value'],
    components: {CalendarPopUp},
    data() {
        return {
            showPopup: false,
            w: new Date().getDay() - 1 === -1 ? 6 : new Date().getDay() - 1,
            d: new Date().getDate(),
            days: ['M', 'T', 'W', 'Thu', 'F', 'Sat', 'S'],
        }
    },
    mounted() {
        if (this.value && this.value.day) {
            this.w = new Date(this.value.day).getDay() - 1 === -1 ? 6 : this.value.day.getDay() - 1
            this.d = new Date(this.value.day).getDate()
        }
    },
    methods: {
        setValues(vals) {
            this.$emit('input', vals)
            this.showPopup = false
        },
        openPopUp() {
            this.showPopup = true
        },
        getText() {
            if (!this.value || !this.value.repeat || this.value.repeat === 'default') return this.$t('ttlEmptyField')
            let pDays = JSON.parse(JSON.stringify(this.value.days))

            switch (this.value.repeat) {
                case 'dayOfWeek':
                    return pDays.sort().map(day => this.daysHelper(day)).join()
                default:
                    return this.$t('ttlRepeat-' + this.value.repeat)
            }
        },
        daysHelper(day) {
            return this.$t('ttlDatePickerShorterDay' + this.days[day])
        },
    }
}
</script>

<style lang="scss">
    .picker-time-field {
        color: #32986F;
        .md-icon.md-icon-font    {
            color: #32986F;
        }
        span {
            font-size: 16px;
            text-decoration: underline;
            padding-left: 10px;
            font-weight: 400;
        }
    }
</style>