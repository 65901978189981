<template>
    <div class="upload__field">
        <md-file
            placeholder="Upload file"
            ref="fileUploadInput"
            required
            @change="uploadFile($event)"
            :accept="field.fileTypes"
        ></md-file>
    </div>
</template>

<script>
import {api} from "@/utils/api";

export default {
    name: "UploadPhotosField",
    props: ["value", "field"],
    data() {
        return {
            photo: null
        }
    },
    methods: {
        uploadFile(evt) {
            /**
             *  upload photos in server
             */

            let self = this;
            const fileSize = evt.target.files[0].size;
            const fileType = evt.target.files[0].type;
            const maxFileSize = Number(this.field.maxFileSize) || 100000;
            const fileTypes = this.field.fileTypes || 'image/jpeg,image/png,image/jpg';

            // Check file size and type
            if (fileSize <= maxFileSize && (fileTypes.indexOf(fileType) + 1)) {
                let formData = new FormData();
                formData.append("type", this.field.type);
                formData.append("data", evt.target.files[0]);
                api.call('', 'post', `/api/media/upload`, formData).then(result => {
                    this.$emit("input", result.filename)
                })
            } else {
                alert(this.field.errorMessage || 'PNG, JPG и JPEG only. Max 100 KB.');

                setTimeout(() => {
                    self.$refs.fileUploadInput.$el.children[1].value = '';
                    self.$refs.fileUploadInput.$el.children[2].value = '';
                }, 10);
            }
        }
    },
}
</script>

<style scoped>

</style>
